.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}
.space {
  height: 10vmin;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

